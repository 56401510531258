<template>
  <div id="test-selection" class="test-admin">
    <!--  Jumbotron  -->
    <b-container fluid class="p-0">
      <b-row no-gutters class="stacked-backgrounds overflow-hidden" align-v="center">
        <b-col cols="12" md="5" offset-md="1"
               class="my-5">
          <h1 id="headline" class="m-3 d-none d-md-block">
            {{ reactiveText.headline[userType] }}
          </h1>
          <h2 class="m-3 d-md-none white">
            {{ reactiveText.headline[userType] }}
          </h2>
          <h5 class="lead m-3">
            {{ reactiveText.cta[userType] }}
          </h5>
          <b-row v-if="tests">
            <b-col cols="11" md="4">
              <b-button id="scroll-button" v-scroll-to="{ el:'#test-list', duration:1000}" block
                        class="m-3" variant="primary">
                {{ reactiveText.headerButton[userType] }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="5" offset-md="1"
               class="text-right d-none d-md-block">
          <b-img class="promo-image" :src="images[userType]" />
        </b-col>
        <b-col cols="12" md="5" offset-md="1"
               class="text-center d-md-none">
          <b-img class="promo-small-image" :src="images[userType]" />
        </b-col>
      </b-row>
      <div>
        <!-- Challenge boxes -->
        <b-row class="m-0 not-quite-white">
          <b-col cols="12" md="10" offset-md="1">
            <b-row class="m-0 not-quite-white">
              <b-col v-for="(challenge, index) in reactiveText.challenges" :key="index" cols="12"
                     md="4" class="p-4">
                <font-awesome-icon :icon="challenge.challengeIcon" size="lg" class="sp-green" />
                <h5 :id="'challenge-' + index" class="my-2">
                  {{ challenge.challengeHead[userType] }}
                </h5>
                <p>{{ challenge.challengeText[userType] }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <!--  Test List  -->
    <b-container id="test-list" class="question-padding">
      <b-row v-if="tests" class="my-5">
        <b-col>
          <h3 class="text-center">
            Choose a test topic to get started
          </h3>
        </b-col>
      </b-row>
      <b-row v-if="tests">
        <b-col>
          <b-row v-for="(test, index) in tests" :key="index" class="p-5">
            <b-col>
              <b-row>
                <b-col cols="12" md="6" class="text-right">
                  <b-img fluid :src="test.image_url" class="p-4" />
                </b-col>
                <b-col>
                  <h2 :id="'test-title-' + index">
                    {{ test.name }}
                  </h2>
                  <p :id="'test-description-' + index">
                    {{ test.description }}
                  </p>
                  <p><b>Skills Analyzed:</b></p>
                  <p :id="'test-skills-' + index">
                    {{ test.skills }}
                  </p>
                  <b-row>
                    <b-col class="text-center text-md-left">
                      <b-button id="test-select-button" variant="primary" class="my-4"
                                @click="next(index)">
                        Select this Test
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="p-2 pt-5">
                  <hr>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="m-5">
              <h3 class="text-center">
                More Skills Analyzer tests coming soon!
              </h3>
              <p id="usertype-button-description" class="text-center">
                {{ $route.params.userType === 'business' ? 'Don’t need Skills Analyzer for the whole team?': 'Looking to test your whole team with Skills Analyzer?' }}
                <b-link id="usertype-button" :to="{
                  name: 'skillsgapTestSelection',
                  params: {
                    userType: $route.params.userType === 'business' ? 'personal': 'business'
                  }}">
                  Go here
                </b-link>
                {{ $route.params.userType === 'business' ? 'to take these tests on your own.': 'to set up a group test' }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else class="m-5">
        <b-col class="m-4">
          <h3 class="text-center">
            Network Connection Error
          </h3>
          <h5 class="text-center">
            Please double check your internet connection and then refresh the browser (F5)
          </h5>
        </b-col>
      </b-row>
    </b-container>
    <!--  Footer Banner  -->
    <b-container id="test-selection-footer" fluid class="p-0">
      <b-row class="m-0 text-left footer-background">
        <b-col cols="11" md="6" offset="1"
               class="py-5">
          <h3 class="white">
            Need a refresh first?
          </h3>
          <p class="my-3 white">
            Help your team keep their skills sharp with 5,000+ video tutorials on CAD, CAM, and BIM software and popular engineering methods in the SolidProfessor Library.
          </p>
          <b-button variant="outline-primary" class="footer-button" :to="{ name: 'library' }">
            Explore Our Courses
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'SkillsgapTestSelection',
  data() {
    return {
      images: {
        personal: 'https://solidprofessor-cdn.s3-us-west-2.amazonaws.com/assets/client-app/skillsgap/skillsgap-banner-promo-user.png',
        business: 'https://solidprofessor-cdn.s3-us-west-2.amazonaws.com/assets/client-app/skillsgap/skillsgap-banner-promo1.png'
      },
      reactiveText: {
        headline: {
          personal: 'My Skills Analyzer',
          business: 'SolidProfessor Skills Analyzer for Teams'
        },
        cta: {
          personal: 'Quantify your engineering design skill set with SolidProfessor Skills Analyzer.',
          business: 'SolidProfessor Skills Analyzer will help you measure your team’s software and design proficiency to uncover skill gaps'
        },
        headerButton: {
          personal: 'Test My Skills',
          business: 'Test Your Team'
        },
        challenges: [
          {
            challengeIcon: 'plus-circle',
            challengeHead: {
              personal: 'Step One: Test',
              business: 'Test Your Team'
            },
            challengeText: {
              personal: 'Choose a topic to start your test! Each test features challenging questions from SolidProfessor tutorials.',
              business: 'Create a test, and share with your team to start identifying skill gaps with assessments from our library'
            },
          },
          {
            challengeIcon: 'analytics',
            challengeHead: {
              personal: 'Analyze Results',
              business: 'Analyze Results'
            },
            challengeText: {
              personal: 'Get your results instantly and measure proficiency. See where you excel and where to focus for improvement.',
              business: 'Get your team results and skills gap analysis instantly on your dashboard, then set goals for improvement.'
            }
          },
          {
            challengeIcon: 'lightbulb-on',
            challengeHead: {
              personal: 'Train & Improve',
              business: 'Upskill & Retain'
            },
            challengeText: {
              personal: 'Elevate your skills with video tutorial recommendations from the SolidProfessor Library based on your results.',
              business: 'Start upskilling your team and build an online training program with our course recommendations.'
            }
          }
        ],
      },
      userType: '',
    };
  },
  computed: {
    tests () {
      return this.$store.getters['sptests/getTestBank'];
    },
  },
  beforeCreate() {
    // If not expect param push to default
    if (this.$route.params.userType !== 'personal' && this.$route.params.userType !== 'business') {
      this.$router.push({name: 'skillsgapTestSelection', params: {userType: 'personal'}}).catch(err => {});
    }
    this.$store.dispatch('sptests/getBaseTests', {param1: 0, param2: 1, param3: 2});
  },
  created() {
    this.userType = this.$route.params.userType;
  },
  methods: {
    /**
     * Depending on user type, pushes to next step
     * @param testId
     * @return {void}
     */
    next(testId) {
      this.selectTest(testId);
      if (this.$route.params.userType === 'business') {
        this.$router.push({name: 'skillsgapTestBuilder', }).catch(err => {});
      } else if (this.$route.params.userType === 'personal') {
        // Handles the 'Test My Skills' button, just sends them to the first test
        if (testId == null) {
          testId = 0;
          this.selectTest(testId);
        }
        this.$router.push({name: 'skillsgap', params: {userType: this.$route.params.userType, testGroupId: btoa(testId)}}).catch(err => {});
      }
    },
    /**
     * Sets user selected test on the store
     * @param testId
     * @return {void}
     */
    selectTest(testId) {
      this.$store.commit('sptests/setSelectedTestId', testId);
    }
  },
};
</script>

<style lang="scss">
.test-admin {
  .full-width {
    max-width: 100%;
    width: 100%;
  }
  .footer-background {
    background-image:
    linear-gradient(-90deg, rgba(45, 124, 194, 0.91) 20%, #00295B 68.75%),
    url(https://solidprofessor-cdn.s3-us-west-2.amazonaws.com/assets/client-app/skillsgap/skillsgap-tutorials-slanted.png);

    background-repeat: no-repeat, no-repeat;
    background-size: 100%, 100%;
    background-position: left, right;
  }
  .footer-button {
    color: white;
    border-color: white;
  }
  h1 {
    align-items: center;
    color: #FFFFFF;
  }
  h2 {
    font-size: 36px;
    line-height: 48px;
    color: #414141;
  }
  .jumbotron {
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    height: 60vh;
    .container {
      margin: 5vw;
      width: 100%;
    }
  }
  .lead {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    align-items: center;

    color: #FFFFFF;
  }
  .not-quite-white {
    background: #F8F8F8
  }
  .promo-image {
    height: 60vh;
  }
  .promo-small-image {
    width: 100%;
    height: auto;
  }
  .question-padding {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .stacked-backgrounds {
    background-image: url(https://solidprofessor-cdn.s3-us-west-2.amazonaws.com/assets/client-app/skillsgap/skillsgap-texture-bg.png),
    linear-gradient(-90deg, rgba(45, 124, 194, 0.91) 0%, #00295B 68.75%);
    background-repeat: repeat, no-repeat;
    background-size: 100%, 100%;
    background-position: left, right;
  }
}
</style>
